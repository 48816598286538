<template>
    <v-navigation-drawer right class="navigation-drawer" :value="dialog" width="400px" app>
        <v-card tile height="100vh">
            <v-toolbar>
                <v-toolbar-title>Nota</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
            </v-toolbar>
            <v-card-text class="scroll">
                <v-img height="100%" width="100%" class="white" :src="invoiceImg"></v-img>
            </v-card-text>
            <v-card-actions class="pa-0">
                <v-toolbar class="fixed-footer">
                    <v-btn color="success" x-large @click="e_credit_note()" v-if="this.item.doc.doc_type==='NC'"> Credit </v-btn>
                    <v-btn color="success" x-large @click="e_debit_note()" v-if="item.doc.doc_type==='ND'"> Debit </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" x-large @click="print_doc()"> Imprimir </v-btn>
                </v-toolbar>
            </v-card-actions>
        </v-card>



        <v-dialog v-model="dian_dialog" transition="dialog-fade-transition" scrollable max-width="100%"
            :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar dark color="success">
                        DIAN
                        <v-spacer></v-spacer>
                        <v-icon @click="dian_dialog = !dian_dialog"> mdi-close </v-icon>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pt-4" v-if="dian_response.is_valid">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-text-field readonly v-model="dian_response.status_description" label="Descripción"
                                hide-details="auto" outlined />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field readonly v-model="dian_response.status_message" label="Descripción"
                                hide-details="auto" outlined />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field readonly v-model="dian_response.issue_date" label="Fecha" hide-details="auto"
                                outlined />
                        </v-col>
                        <v-col cols="12" md="12" v-if="dian_response.is_valid">
                            <iframe :src="dian_response.qr_link" width="100%" height="80vh" frameborder="0"
                                class="cart-list">
                            </iframe>
                        </v-col>
                    </v-row>
                    <v-btn light color="success" x-large :href="dian_response.pdf_download_link" target="_blank">
                        Descargar PDF
                    </v-btn>
                </v-card-text>
                <v-card-text class="pt-4" v-if="dian_response.message">
                    <v-alert outlined type="error">
                        {{ dian_response.message }}
                    </v-alert>
                    <!-- <v-textarea label="Errors" v-model="dian_response.errors"></v-textarea> -->
                    <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
                </v-card-text>
            </v-card>

        </v-dialog>

        <v-dialog v-model="loading_status" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text class="pa-6">
                    <p>Proceso</p>
                    <p>Conectando con DIAN.</p>
                    <v-progress-linear indeterminate color="green" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-navigation-drawer>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
import createDoc from "../utils/create_doc.js";
import dianResponse from "../utils/dianResponse.js";
//import { webserver } from "../services/webserver.js";
export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: createDoc(),
        },
        dialog: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    mounted() {

    },
    data() {
        return {
            invoiceImg: null,
            dian_dialog: false,
            dian_response: dianResponse(),
            docview: "",
            loading_status: false,
        }
    },
    methods: {
        print_doc() {
            var a = window.open("", "", "height=600, width=400");
            a.document.write('<img src="' + this.dataURL + '"  width="100%">');
            a.print();
            a.document.close();
            setTimeout(() => {
                a.close();
            }, 500);
        },
        print_invoice(data) {
            var LS = 50;
            var itemsQty = data.items.length * LS;
            var elem = document.createElement("canvas");
            elem.width = 945;
            elem.height = 1800 + itemsQty;
            var total = 0;
            var center = elem.width / 2;
            var left = 10;
            var line = 150;
            var right = elem.width - left;
            const ctx = elem.getContext("2d");
            var items = data.items;
            items.forEach((item) => {
                item.quantity = parseInt(item.quantity);
                item.price = parseInt(item.price);
                item.discount = parseInt(item.discount);
            });
            var regimen = "NO RESPONSABLES DEL IVA";
            if (data.store.TaxLevelCode) {
                switch (data.store.TaxLevelCode) {
                    case "0":
                        regimen = "NO RESPONSABLES DEL IVA";
                        break;
                    case "1":
                        regimen = "RESPONSABLES DEL IVA";
                        break;
                    default:
                        regimen = "NO RESPONSABLES DEL IVA";
                }
            }
            ctx.font = "bold 40px Arial";
            ctx.textAlign = "center";
            ctx.font = "bold 60px Arial";
            ctx.fillText(data.store.PartyIdentification_Id, center, line);
            line += LS;
            ctx.font = "bold 40px Arial";
            ctx.fillText(data.store.PartyName, center, line);
            line += LS;
            ctx.fillText("Nit : " + data.store.PartyIdentification, center, line);
            line += LS;
            ctx.fillText(regimen, center, line);
            line += LS;
            ctx.fillText(
                data.store.AddressLine + ", " + data.store.CityName,
                center,
                line
            );
            line += LS;
            // ctx.fillText(
            //     "Resolución DIAN: " + data.permit.InvoiceAuthorization,
            //     center,
            //     line
            // );
            line += LS;
            // ctx.fillText(
            //     "Del: " +
            //     data.permit.Prefix +
            //     "-" +
            //     data.permit.AuthorizationFrom +
            //     " al " +
            //     data.permit.Prefix +
            //     "-" +
            //     data.permit.AuthorizationTo,
            //     center,
            //     line
            // );
            line += LS;
            // ctx.fillText(
            //     "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
            //     center,
            //     line
            // );
            line += LS;
            ctx.font = "bold 35px Arial";
            ctx.fillText("Documento Equivalente a la Factura de Venta", center, line);
            ctx.font = "bold 40px Arial";
            line += LS;
            ctx.fillText("No. " + data.doc.invoice, center, line);
            line += LS;
            ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
            line += LS;
            ctx.fillText("NO RETENEDORES DE IVA", center, line);
            line += LS;
            ctx.fillText("Sistema POS", center, line);
            line += LS;
            if (data.payments[0].hora) {
                ctx.fillText(
                    data.doc.issueDate + " " + data.payments[0].hora,
                    center,
                    line
                );
            } else {
                ctx.fillText(data.doc.issueDate, center, line);
            }

            line += LS;
            ctx.textAlign = "start";
            ctx.fillText("C.C./Nit : " + data.doc.PartyIdentification, left, line);
            line += LS;
            ctx.fillText("Nombre    : " + data.doc.PartyName, left, line);
            line += LS;
            line += LS;
            LS = 60;
            ctx.font = "bold 50px Arial";
            ctx.textAlign = "center";
            ctx.fillText("Productos", center, line);
            line += LS;
            ctx.beginPath();
            ctx.moveTo(left, line - 10);
            ctx.lineTo(right, line - 10);
            ctx.stroke();
            line += LS;
            total = 0;
            var tdiscount = 0;
            items.forEach((row) => {
                var price = row.item_price * row.item_quantity;
                var discount = row.item_discount * row.item_quantity;
                ctx.textAlign = "start";
                ctx.fillText(row.item_quantity, left, line);
                ctx.textAlign = "start";
                ctx.fillText(row.item_concept, left + 50, line);
                ctx.textAlign = "end";
                ctx.fillText(row.item_price.toLocaleString(), right, line);
                line += LS;
                total += price;
                tdiscount += discount;
            });
            ctx.beginPath();
            ctx.moveTo(left, line - 10);
            ctx.lineTo(right, line - 10);
            ctx.stroke();
            line += LS;
            var tax_value = 1.19;
            var sin_iva = Math.round(total / tax_value);
            var iva = total - sin_iva;
            ctx.textAlign = "start";
            ctx.fillText("Base gravable", left, line);
            ctx.textAlign = "end";
            ctx.fillText(sin_iva.toLocaleString(), right, line);
            line += LS;
            ctx.textAlign = "start";
            ctx.fillText(
                "IVA " + Math.round((tax_value - 1) * 100) + "%",
                left,
                line
            );
            ctx.textAlign = "end";
            ctx.fillText(iva.toLocaleString(), right, line);
            line += LS;
            ctx.textAlign = "start";
            ctx.fillText("TOTAL", left, line);
            ctx.textAlign = "end";
            ctx.fillText(total.toLocaleString(), right, line);
            line += LS;

            if (tdiscount > 0) {
                ctx.textAlign = "start";
                ctx.fillText("Descuento aplicado", left, line);
                ctx.textAlign = "end";
                ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
                line += LS;
            }
            line += LS;

            ctx.textAlign = "center";
            ctx.fillText("Formas de pago", center, line);
            line += LS;
            //console.log(data.payments);
            data.payments.forEach((row) => {
                //console.log(row);
                ctx.textAlign = "start";
                ctx.fillText(row.payment_concept + " " + row.payment_reference, left, line);
                ctx.textAlign = "end";
                ctx.fillText(row.payment_value.toLocaleString(), right, line);
                line += LS;
            });
            line += LS;
            ctx.textAlign = "center";
            if (window.store.store_footer) {
                var res = window.store.store_footer.split("<br>");
                res.forEach((item) => {
                    ctx.fillText(item, center, line);
                    line += LS;
                });
            } else {
                ctx.fillText("! Gracias por su compra !", center, line);
            }
            line += LS;
            line += LS;
            line += LS;

            line += LS;
            line += LS;

            this.dataURL = elem.toDataURL();
            this.invoiceImg = this.dataURL;
            this.showPrint = true;


        },
        put_credit_note(data) {
            this.loading_status = true;
            var qry = {
                method: "POST",
                nit: this.$store.state.seller.store_business_id,
                data: data,
                store: this.item.doc.store,
                issuedate: this.item.doc.issuedate,
                did: this.item.doc.doc_number,
            };
            //console.log(this.item);
            this.loading_status = true;
            fe_ws("post_credit_note", qry, (data) => {
                this.loading_status = false;
                //console.log(JSON.parse(data.data));
                this.dian_dialog = true;
                this.dian_response = JSON.parse(data.data);
                this.loading_status = false;
            });
        },
        put_debit_note(data) {
            this.loading_status = true;
            var qry = {
                method: "POST",
                nit: this.$store.state.seller.store_business_id,
                data: data,
                store: this.item.doc.store,
                issuedate: this.item.doc.issuedate,
                did: this.item.doc.doc_number,
            };
            //console.log(this.item);
            this.loading_status = true;
            fe_ws("post_debit_note", qry, (data) => {
                this.loading_status = false;
                //console.log(JSON.parse(data.data));
                this.dian_dialog = true;
                this.dian_response = JSON.parse(data.data);
                this.loading_status = false;
            });
        },
        e_credit_note() {
            //console.log(this.item);
            var doc = this.item.doc;
            var itms = this.item.items;
            //var payments = e.payments;
            doc.city_code = 126;

            var eInv = {
                discrepancy_response: {
                    correction_concept_id: 2,
                },
                billing_reference: {
                    number: doc.invoice.replace("-", ""),
                    uuid: doc.cufe,
                    issue_date: doc.issuedate
                },
                number: doc.doc_number.split("-")[2],
                sync: true,
                type_document_id: 5,
                customer: {
                    identification_number: doc.PartyIdentification,
                    name: doc.PartyName,
                    municipality_id: doc.city_code,
                    email: doc.PartyEmail,
                },
                legal_monetary_totals: {
                    line_extension_amount: 0,
                    tax_exclusive_amount: 0,
                    tax_inclusive_amount: 0,
                    allowance_total_amount: 0,
                    charge_total_amount: 0,
                    payable_amount: 0
                },
                credit_note_lines: []
            }

            var tax_value = 1.19;
            var taxvalue = 19.00;

            itms.forEach((item) => {
                item.item_quantity = parseFloat(item.item_quantity);
                //Valor del artículo o servicio
                item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
                item.discount = parseFloat(item.item_discount).toFixed(2);
                item.tax = ((((parseFloat(item.price_amount) * parseFloat(item.item_quantity))) * taxvalue) / 100).toFixed(2);
                //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
                item.line_extension_amount = (parseFloat(item.price_amount) * parseFloat(item.item_quantity)).toFixed(2);
            });

            itms.forEach((item) => {
                var itm = {
                    description: item.item_concept,
                    unit_measure_id: 642,
                    code: item.item_code,
                    type_item_identification_id: 3,
                    base_quantity: item.item_quantity.toFixed(6),
                    invoiced_quantity: item.item_quantity.toFixed(6),
                    price_amount: item.price_amount,
                    line_extension_amount: item.line_extension_amount,
                }
                if (parseFloat(item.item_discount) > 0) {
                    itm.allowance_charges = [{
                        charge_indicator: false,
                        allowance_charge_reason: "Discount",
                        amount: item.item_discount,
                        base_amount: (parseFloat(item.price_amount) * parseFloat(item.item_quantity)).toFixed(2)
                    }];
                    eInv.legal_monetary_totals.allowance_total_amount += parseFloat(item.item_discount);
                }
                itm.tax_totals = [
                    {
                        "tax_id": 1,
                        "tax_amount": item.tax,
                        "taxable_amount": ((parseFloat(item.price_amount) * parseFloat(item.item_quantity))).toFixed(2),
                        "percent": taxvalue.toFixed(2),
                    }
                ];
                eInv.legal_monetary_totals.line_extension_amount += parseFloat(itm.line_extension_amount);//Valores totales aplicables a la factura
                eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(item.line_extension_amount);// Total Valor Bruto (Antes de tributos)
                eInv.legal_monetary_totals.tax_inclusive_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.legal_monetary_totals.charge_total_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.legal_monetary_totals.payable_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.credit_note_lines.push(itm);

            });

            var payments = this.item.payments;
            var bags = payments.find(item => item.payment_concept == "ICB ley 1819 de 2016");
            if (bags) {
                bags.quantity = parseFloat(bags.payment_value) / 51;
                bags.tax = 51;
                var itmBags = {
                    description: "Bolsas",
                    unit_measure_id: 886,
                    code: "000000000000",
                    type_item_identification_id: 3,
                    base_quantity: "1.000000",
                    invoiced_quantity: bags.quantity.toFixed(6),
                    price_amount: parseFloat(bags.payment_value).toFixed(2),
                    line_extension_amount: "0.00",
                    reference_price_id: 1,
                    tax_totals: [
                        {
                            tax_id: 10,
                            tax_amount: parseFloat(bags.payment_value).toFixed(2),
                            taxable_amount: parseFloat(bags.quantity).toFixed(2),
                            unit_measure_id: 886,
                            per_unit_amount: bags.tax.toFixed(2),
                            base_unit_measure: bags.quantity.toFixed(6),
                        }
                    ],
                    //  parseFloat(bags.quantity).toFixed(2),
                };
                // 
                eInv.legal_monetary_totals.line_extension_amount += parseFloat(itmBags.line_extension_amount);//Valores totales aplicables a la factura
                eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(itmBags.line_extension_amount);// Total Valor Bruto (Antes de tributos)
                eInv.legal_monetary_totals.tax_inclusive_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.legal_monetary_totals.charge_total_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.legal_monetary_totals.payable_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.credit_note_lines.push(itmBags);
            }


            eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(2);//Valores totales aplicables a la factura
            eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(2);// Total Valor Bruto (Antes de tributos)
            eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(2);
            eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(2);
            eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(2);
            eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(2);
            //console.log(eInv);
            this.put_credit_note(eInv);

        },
        e_debit_note() {
            //console.log(this.item);
            var doc = this.item.doc;
            var itms = this.item.items;
            doc.city_code = 126;

            var eInv = {
                discrepancy_response: {
                    correction_concept_id: 9,
                },
                billing_reference: {
                    number: doc.invoice.replace("-", ""),
                    uuid: doc.cufe,
                    issue_date: doc.issuedate
                },
                number: doc.doc_number.split("-")[2],
                sync: true,
                type_document_id: 6,
                customer: {
                    identification_number: doc.PartyIdentification,
                    name: doc.PartyName,
                    municipality_id: doc.city_code,
                    email: doc.PartyEmail,
                },
                requested_monetary_totals: {
                    line_extension_amount: 0,
                    tax_exclusive_amount: 0,
                    tax_inclusive_amount: 0,
                    allowance_total_amount: 0,
                    charge_total_amount: 0,
                    payable_amount: 0
                },
                debit_note_lines: []
            }

            var tax_value = 1.19;
            var taxvalue = 19.00;

            itms.forEach((item) => {
                item.item_quantity = parseFloat(item.item_quantity);
                //Valor del artículo o servicio
                item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
                item.discount = parseFloat(item.item_discount).toFixed(2);
                item.tax = ((((parseFloat(item.price_amount) * parseFloat(item.item_quantity))) * taxvalue) / 100).toFixed(2);
                //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
                item.line_extension_amount = (parseFloat(item.price_amount) * parseFloat(item.item_quantity)).toFixed(2);
            });

            itms.forEach((item) => {
                var itm = {
                    description: item.item_concept,
                    unit_measure_id: 642,
                    code: item.item_code,
                    type_item_identification_id: 3,
                    base_quantity: item.item_quantity.toFixed(6),
                    invoiced_quantity: item.item_quantity.toFixed(6),
                    price_amount: item.price_amount,
                    line_extension_amount: item.line_extension_amount,
                }
                if (parseFloat(item.item_discount) > 0) {
                    itm.allowance_charges = [{
                        charge_indicator: false,
                        allowance_charge_reason: "Discount",
                        amount: item.item_discount,
                        base_amount: (parseFloat(item.price_amount) * parseFloat(item.item_quantity)).toFixed(2)
                    }];
                    eInv.legal_monetary_totals.allowance_total_amount += parseFloat(item.item_discount);
                }
                itm.tax_totals = [
                    {
                        "tax_id": 1,
                        "tax_amount": item.tax,
                        "taxable_amount": ((parseFloat(item.price_amount) * parseFloat(item.item_quantity))).toFixed(2),
                        "percent": taxvalue.toFixed(2),
                    }
                ];
                eInv.requested_monetary_totals.line_extension_amount += parseFloat(itm.line_extension_amount);//Valores totales aplicables a la factura
                eInv.requested_monetary_totals.tax_exclusive_amount += parseFloat(item.line_extension_amount);// Total Valor Bruto (Antes de tributos)
                eInv.requested_monetary_totals.tax_inclusive_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.requested_monetary_totals.charge_total_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.requested_monetary_totals.payable_amount += parseFloat(item.line_extension_amount) + parseFloat(item.tax);
                eInv.debit_note_lines.push(itm);

            });

            var payments = this.item.payments;
            var bags = payments.find(item => item.payment_concept == "ICB ley 1819 de 2016");
            if (bags) {
                bags.quantity = parseFloat(bags.payment_value) / 51;
                bags.tax = 51;
                var itmBags = {
                    description: "Bolsas",
                    unit_measure_id: 886,
                    code: "000000000000",
                    type_item_identification_id: 3,
                    base_quantity: "1.000000",
                    invoiced_quantity: bags.quantity.toFixed(6),
                    price_amount: parseFloat(bags.payment_value).toFixed(2),
                    line_extension_amount: "0.00",
                    reference_price_id: 1,
                    tax_totals: [
                        {
                            tax_id: 10,
                            tax_amount: parseFloat(bags.payment_value).toFixed(2),
                            taxable_amount: parseFloat(bags.quantity).toFixed(2),
                            unit_measure_id: 886,
                            per_unit_amount: bags.tax.toFixed(2),
                            base_unit_measure: bags.quantity.toFixed(6),
                        }
                    ],
                    //  parseFloat(bags.quantity).toFixed(2),
                };
                // 
                eInv.requested_monetary_totals.line_extension_amount += parseFloat(itmBags.line_extension_amount);//Valores totales aplicables a la factura
                eInv.requested_monetary_totals.tax_exclusive_amount += parseFloat(itmBags.line_extension_amount);// Total Valor Bruto (Antes de tributos)
                eInv.requested_monetary_totals.tax_inclusive_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.requested_monetary_totals.charge_total_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.requested_monetary_totals.payable_amount += parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
                eInv.debit_note_lines.push(itmBags);
            }
            eInv.requested_monetary_totals.line_extension_amount = eInv.requested_monetary_totals.line_extension_amount.toFixed(2);//Valores totales aplicables a la factura
            eInv.requested_monetary_totals.tax_exclusive_amount = eInv.requested_monetary_totals.tax_exclusive_amount.toFixed(2);// Total Valor Bruto (Antes de tributos)
            eInv.requested_monetary_totals.tax_inclusive_amount = eInv.requested_monetary_totals.tax_inclusive_amount.toFixed(2);
            eInv.requested_monetary_totals.allowance_total_amount = eInv.requested_monetary_totals.allowance_total_amount.toFixed(2);
            eInv.requested_monetary_totals.charge_total_amount = eInv.requested_monetary_totals.charge_total_amount.toFixed(2);
            eInv.requested_monetary_totals.payable_amount = eInv.requested_monetary_totals.payable_amount.toFixed(2);
            //console.log(eInv);
            this.put_debit_note(eInv);

        }
    },
    watch: {
        item: function (e) {
            console.log(e);
            this.print_invoice(e);
        },
    },

}
</script>

<style>
</style>